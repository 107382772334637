import { PropsWithChildren, useCallback } from 'react'

import { Tune, Autorenew } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import classnames from 'classnames'

import { useUpdateBargePoolMutation } from '../../../generated/graphql'
import { HamburgerIcon } from '../../../ui/HamburgerIcon/HamburgerIcon'
import { successToast } from '../../../ui/Toast/Toast'
import { LastBargePoolUpdate } from '../LastBargePoolUpdate/LastBargePoolUpdate'

import styles from './PrimaryMenu.module.scss'

export const ToggleFilterScreenButton = ({
  handleClick,
  isDisabled = false,
}: {
  isDisabled?: boolean
  handleClick?: () => void
}) => (
  <button onClick={handleClick} disabled={isDisabled} className={styles.menuItem}>
    <Tune />
  </button>
)

export const LastUpdateIndicator = () => (
  <div className={styles.menuItem}>
    <LastBargePoolUpdate />
  </div>
)

export const UpdateBargePoolButton = () => {
  const [, updateBargePoolMutation] = useUpdateBargePoolMutation()

  const updatePoolHandler = useCallback(async () => {
    await updateBargePoolMutation({}, { requestPolicy: 'network-only' })
    successToast('Barge pool updated is scheduled. It can take up to 2 minutes to update.')
  }, [updateBargePoolMutation])

  return (
    <Tooltip title="Request barge pool update">
      <button className={classnames([styles.menuItem, styles.poolRefresh])} onClick={updatePoolHandler}>
        <Autorenew />
      </button>
    </Tooltip>
  )
}

export const BargePoolUpdateBlock = () => (
  <div className={styles.bargePoolUpdateBlock}>
    <UpdateBargePoolButton />
    <LastUpdateIndicator />
  </div>
)

export function PrimaryMenu({
  isDrawerOpen,
  setDrawerOpen,
  children,
}: PropsWithChildren<{ isDrawerOpen: boolean; setDrawerOpen: (b: boolean) => void }>) {
  return (
    <header className={styles.primaryMenu}>
      <button type="button" className={styles.menuItem} onClick={() => setDrawerOpen(!isDrawerOpen)}>
        <HamburgerIcon isOpen={isDrawerOpen} />
      </button>
      {children ?? null}
    </header>
  )
}

export const PrimaryMenuRight: React.FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.right}>{children}</div>
)
