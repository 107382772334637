import React from 'react'

import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import TooltipIcon from '../../../ui/ToolTipIcon/ToolTipIcon'

import styles from './styles.module.scss'

interface FormPartValidityIndicatorProps {
  isValid: boolean;
}

function FormPartValidityIndicator({ isValid }: FormPartValidityIndicatorProps) {
  const tooltipText = isValid
    ? 'All required fields are completed'
    : 'Please review the information in this section'

  const icon = isValid ? (
    <CheckIcon className={styles.iconValid} />
  ) : (
    <ErrorOutlineIcon className={styles.iconInvalid} />
  )

  return <TooltipIcon isActive tooltip={tooltipText} icon={icon} />
}

export default FormPartValidityIndicator
