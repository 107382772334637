import React from 'react'

import { FormikErrors, FormikTouched } from 'formik'

import { GoalId } from '../../../../generated/graphql'
import { ErrorNotification } from '../../../../ui/Form/ErrorNotifcation'
import { TowParametersFormValues } from '../types'

import { GoalSelection } from './FormComponents'

type OperationalGoalFormProps = {
  data: TowParametersFormValues
  onChange: (config: TowParametersFormValues) => void
  goals: Record<GoalId, { label: string; isDisabled: boolean }>
  errors?: FormikErrors<TowParametersFormValues>
  touched?: FormikTouched<TowParametersFormValues>
  setFieldValue: (field: string, value: any) => void
}

const OperationalGoalForm = ({
  data,
  onChange,
  goals,
  errors,
  setFieldValue,
}: OperationalGoalFormProps) => {

  const handleGoalSelect = (goal: GoalId) => {
    setFieldValue('goal', goal)
    onChange({ ...data, goal })
  }

  const selectedGoal = data.goal && goals[data.goal] ? data.goal : null

  const goalError = errors?.goal

  return (
    <>
      <GoalSelection
        goals={goals}
        selectedGoal={selectedGoal}
        onSelect={handleGoalSelect}
      />
      { goalError && (
        <ErrorNotification message={goalError} />
      )}
    </>
  )
}

export default OperationalGoalForm
