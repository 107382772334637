import { format } from 'date-fns'
import { utils, writeFileXLSX } from 'xlsx'

import { columns, columnKeys } from '../../../components/Table/BargesTable'
import { ELLIPSIS } from '../../../constants/constants'
import { browserInfo } from '../../../lib/browser-info'

import type { DepartureTimeFormValues, LaneSelectionFormValues, PoolFiltersSelectionFormValues, TowParametersFormValues, VesselSelectionFormValues } from './types'
import type { OverviewBarge } from '../../../Domain/Barge'
import type { UserDetails } from '../../Account/AuthorizationContext'


export function collectFormFeedbackData(
  userInfo: UserDetails,
  lane: LaneSelectionFormValues,
  boat: VesselSelectionFormValues,
  departureTime: DepartureTimeFormValues,
  poolFilters: PoolFiltersSelectionFormValues,
  towParameters: TowParametersFormValues,
  barges: OverviewBarge[],
  recordTime?: Date
) {
  const { email, cognitoUsername } = userInfo
  const info = browserInfo()
  const workbook = utils.book_new()

  const feedbackSheetData = [
    ['info'],
    ['browser', info.browser],
    ['browser version', info.browserVersion],
    ['os', info.os],
    ['device', info.device],
    [],
    ['user'],
    ['email', email],
    ['username', cognitoUsername],
    [],
    ['lane & vessel info'],
    ['lane', lane.laneId ?? ELLIPSIS],
    ['origin', lane.origin ?? ELLIPSIS],
    ['destination', lane.destination ?? ELLIPSIS],
    ['vessel', boat.boatId ?? ELLIPSIS],
    [],
    ['departure time'],
    ['date', departureTime.selectedDate ? format(departureTime.selectedDate, 'yyyy-MM-dd') : ELLIPSIS],
    ['time', departureTime.time ? `${departureTime.time.hours}:${departureTime.time.minutes}` : ELLIPSIS],
    [],
    ['pool filters'],
    ['max draft (feet)', poolFilters.feet ?? ELLIPSIS],
    ['max draft (inches)', poolFilters.inch ?? ELLIPSIS],
    ['exclude tanks', poolFilters.tanks],
    ['exclude open hopper', poolFilters.openHopper],
    ['exclude TBO info barges', poolFilters.havingTboInfo],
    ['exclude placed-to-load barges', poolFilters.placedToLoad],
    ['exclude shuttle moves', poolFilters.shuttleMoves],
    ['include TBOs', poolFilters.includeTBOs.length > 0 ? poolFilters.includeTBOs.join(', ') : ELLIPSIS],
    [],
    ['tow parameters'],
    ['goal', towParameters.goal || ELLIPSIS],
    ['number of loaded barges', towParameters.numberOfLoaded],
    ['number of empty barges', towParameters.numberOfEmpties],
    ['number of strings', towParameters.numberOfStrings ?? ELLIPSIS],
    ['prioritize hot barges', towParameters.hotBarges],
    [],
    ['export time', format(recordTime || new Date(), 'yyyy-MM-dd HH:mm:ss')],
  ]

  utils.book_append_sheet(workbook, utils.aoa_to_sheet(feedbackSheetData), 'Feedback')


  const bargesSheetData = [
    columnKeys.map(key => columns[key].label),
    ...barges.map(
      barge => columnKeys.map(key => columns[key].format(barge) ?? ELLIPSIS)
    ),
  ]
  utils.book_append_sheet(workbook, utils.aoa_to_sheet(bargesSheetData), 'Barges')


  const fileName = `form-feedback-${format(new Date(), 'yyyy-MM-dd')}.xlsx`
  writeFileXLSX(workbook, fileName)
}
