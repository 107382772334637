import React from 'react'

import { Close } from '@mui/icons-material'
import classnames from 'classnames'

import { BargePoolUpdateBlock, PrimaryMenu, PrimaryMenuRight } from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import { Layout } from '../../../ui/Layout/Layout'

import BargePoolBlock from './BargePoolBlock'
import DepartureTimeSelectionBlock from './DepartureTimeSelectionBlock'
import LaneSelectionBlock from './LaneSelectionBlock'
import PoolFiltersSelectionBlock from './PoolFilters/PoolFiltersSelectionBlock'
import styles from './styles.module.scss'
import TowParametersSelectionBlock from './TowParameters/TowParametersSelectionBlock'
import { NominationStage } from './types'
import VesselSelectionBlock from './Vessel/VesselSelectionBlock'
import useNominationFormViewModel from './viewModel'

type NominationFormProps = {
  id: string | null
}

const NominationFormPage = ({ id }: NominationFormProps) => {
  const { stages, bargePool, withExternalVersionBanner, submit, isSubmitting, backHandler, canBeSubmitted } =
    useNominationFormViewModel(id)

  const banner = withExternalVersionBanner ? (
    <div className={styles.banner}>
      Versions imported from TBO don't have filters set. Verify the parameters before creating the new version.
    </div>
  ) : null

  const buttonName = id ? 'Create Nomination Version' : 'Create Nomination'

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen}>
          <PrimaryMenuRight>
            <BargePoolUpdateBlock />
            <button onClick={backHandler} className={styles.closeButton} aria-label="Close">
              <Close className={styles.iconSize} />
            </button>
          </PrimaryMenuRight>
        </PrimaryMenu>
      )}>
      {banner}
      <div className={styles.container}>
        <div className={classnames([styles.section, styles.formSection])}>
          <div className={styles.formDataBlocks}>
            <LaneSelectionBlock {...stages[NominationStage.LaneSelection]} />
            <VesselSelectionBlock {...stages[NominationStage.VesselSelection]} />
            <DepartureTimeSelectionBlock {...stages[NominationStage.DepartureTimeSelection]} />
            <PoolFiltersSelectionBlock {...stages[NominationStage.PoolFiltersSelection]} />
            <TowParametersSelectionBlock {...stages[NominationStage.TowParametersSelection]} />
          </div>
          <div className={styles.formSubmitBlock}>
            <button onClick={submit} disabled={isSubmitting || !canBeSubmitted}>
              {buttonName}
            </button>
          </div>
        </div>
        <div className={classnames([styles.section, styles.bargePoolSection])}>
          <BargePoolBlock {...bargePool} />
        </div>
      </div>
    </Layout>
  )
}

export default NominationFormPage
