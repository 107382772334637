import React, { type FC, type PropsWithChildren } from 'react'

import FormPartValidityIndicator from './FormPartValidityIndicator'
import styles from './styles.module.scss'

import type { StageSelectionActions } from './types'

type SummaryBlockProps = StageSelectionActions & {
  title: string
  isValid: boolean
}

const SummaryBlock: FC<PropsWithChildren<SummaryBlockProps>> = ({ title, self, isValid, children }) => {
  return (
    <div onClick={self} className={styles.summaryBlock}>
      <div className={styles.blockTitle}>
        <div>{title}</div>
        <FormPartValidityIndicator isValid={isValid} />
      </div>
      <div className={styles.summaryContainer}>{children}</div>
    </div>
  )
}

export default SummaryBlock
