import React from 'react'

import { Formik, Form } from 'formik'

import { OverviewBarge } from '../../../../Domain/Barge'
import { towParametersValidationSchema } from '../viewModel'

import OperationalGoalForm from './OperationalGoalForm'
import PrioritizeHotBarges from './PrioritizeHotBarges'
import TowParametersForm from './TowParametersForm'

import type { GoalId } from '../../../../generated/graphql'
import type { TowParametersFormValues } from '../types'

type TowParametersSelectionFormProps = {
  data: TowParametersFormValues
  onChange: (config: TowParametersFormValues) => void
  barges: OverviewBarge[]
  goals: Record<GoalId, { label: string; isDisabled: boolean }>
  prioritizeHotBarges: boolean
}

const TowParametersSelectionForm = ({
  data,
  onChange,
  barges,
  goals,
}: TowParametersSelectionFormProps) => {
  return (
    <Formik
      initialValues={data}
      validationSchema={towParametersValidationSchema}
      onSubmit={onChange}
      validateOnMount
      validateOnBlur
      validateOnChange
    >
      {({
        errors,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <TowParametersForm
              data={data}
              setData={onChange}
              errors={errors}
              barges={barges}
              setFieldValue={setFieldValue}
            />

            <PrioritizeHotBarges
              barges={barges ?? []}
              prioritizeHotBarges={data.hotBarges}
              setPrioritizeHotBarges={(val) => onChange({ ...data, hotBarges: val })}
            />

            <OperationalGoalForm
              data={data}
              onChange={onChange}
              errors={errors}
              goals={goals}
              setFieldValue={setFieldValue}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default TowParametersSelectionForm
