import React from 'react'

import FormOrSummary from '../FormOrSummary'
import { StageData, VesselSelectionSummaryValues } from '../types'

import VesselSelectionForm from './VesselSelectionForm'
import VesselSelectionSummary from './VesselSelectionSummary'

const VesselSelectionBlock = ({
  isSelected,
  isValid,
  actions,
  form,
  initParameters,
  summary,
}: StageData<any, VesselSelectionSummaryValues, any> & {
}) => {
  const { data, onChange } = form
  const { description } = summary

  return (
    <FormOrSummary
      title="Vessels"
      isValid={isValid}
      isSelected={isSelected}
      actions={actions}
      form={
        <div>
          <VesselSelectionForm
            data={data}
            onChange={onChange}
            initParameters={initParameters}
          />
        </div>
      }
      summary={<VesselSelectionSummary description={description} />}
    />
  )
}

export default VesselSelectionBlock
