import React from 'react'

import BoatSelectorCombobox from '../../../../components/Header/VesselSelector/VesselSelector'
import { Toggle } from '../../../../ui/Toggle/Toggle'
import styles from '../styles.module.scss'
import { VesselSelectionFormValues } from '../types'

const VesselSelectionForm = ({
    data,
    onChange,
    initParameters,
}: {
    data: VesselSelectionFormValues
    onChange: (d: VesselSelectionFormValues) => void
    initParameters: any
}) => {
    const { boats } = initParameters

    return (
        <div className={styles.formRow}>
            <label className={styles.label}>Select a Boat</label>
            <div className={styles.inputContainer}>
                <BoatSelectorCombobox
                    boat={data.boatId}
                    boats={boats}
                    handleChange={value => onChange({ ...data, boatId: value })}
                />
            </div>
            <div className={styles.flexTurnboat}>
                <span>Turnboat</span>
                <Toggle enabled={data.hasTurnboat} onChange={value => onChange({ ...data, hasTurnboat: value })} />
            </div>
        </div>
    )
}

export default VesselSelectionForm
