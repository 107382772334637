import * as R from 'ramda'

import { useNominationByIdQuery } from '../generated/graphql'

import { convertNomination, convertNominationVersion } from './converters'

import type { NominationData, NominationVersionData } from './models'

type NominationDetailsModel = {
  fetching: boolean
  nomination: NominationData | null
  versions: NominationVersionData[]
  refreshNomination: (ctx: any) => void
}

const useNominationsModel = (id: string): NominationDetailsModel => {
  const [{ fetching, data }, refetch] = useNominationByIdQuery({
    variables: { id },
  })

  const fetchedNomination = data?.nominationById?.nomination

  const nomination = fetchedNomination ? convertNomination(fetchedNomination) : null

  const fetchedVersions = data?.nominationById?.versions || []

  const versions = R.map(convertNominationVersion, fetchedVersions)

  return {
    fetching,
    nomination,
    versions,
    refreshNomination: ctx => refetch(ctx),
  }
}

export default useNominationsModel
