import React, { type FC, type PropsWithChildren } from 'react'


import Button from '../../../components/Button'

import FormPartValidityIndicator from './FormPartValidityIndicator'
import styles from './styles.module.scss'

import type { StageChangeActions } from './types'

interface FormBlockWithActionsProps extends StageChangeActions {
  title: string
  isValid: boolean
}

const FormBlockWithActions: FC<PropsWithChildren<FormBlockWithActionsProps>> = ({
  title,
  isValid,
  children,
  previousStage,
  nextStage,
}) => {

  return (
    <div className={styles.formWithActions}>
      <div className={styles.blockTitle}>
        <div>{title}</div>
        <FormPartValidityIndicator isValid={isValid} />
      </div>
      <div className={styles.formBlock}>{children}</div>
      <div className={styles.actionBlock}>
        {previousStage && (
          <Button className={`${styles.button} ${styles.prev}`} onClick={() => previousStage?.()}>
            Previous
          </Button>
        )}
        {nextStage && (
          <Button className={`${styles.button} ${styles.next}`} onClick={() => nextStage?.()}>
            Next
          </Button>
        )}
      </div>
    </div>
  )
}

export default FormBlockWithActions
