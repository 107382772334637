import { parseISO } from 'date-fns'
import * as R from 'ramda'

import { equals } from '../Domain/River'
import {
  type LatestNominationVersionSummary,
  type NominatedBarge as GqlFullNominatedBarge,
  type NominatedTbnBarge as GqlNominatedTbnBarge,
  type NominationReview,
  type OrderRevisionId as GqlOrderRevisionId,
  type OverviewNomination,
  type OverviewNominationVersion as GqlNominationVersionFull,
  OverviewNominationVersionType,
  type OverviewVersionedNominationRequest,
  type RiverLocationLite,
  TboSubmissionStatusId,
  type VersionedNominationFilters,
  type VersionedTowConfiguration,
} from '../generated/graphql'

import type {
  ExternalNominationVersionData,
  LatestNominationSummary,
  NativeNominationVersionData,
  NominatedBarge,
  NominationData,
  NominationVersionData,
  OrderRevisionId,
  StopsWithMetrics,
  TbnBarge,
  Tow,
  VersionedNominationBargeFilers,
  VersionedNominationRequest,
  VersionedNominationTowConfiguration,
} from './models'
import type { NominatedBargeWithReview, NominatedTow } from '../Domain/Nomination'

export type GqlNomination = Omit<OverviewNomination, 'ownerId' | 'owner'> & {
  owner: { username: string } | null
}

export type GqlNominationRequest = OverviewVersionedNominationRequest

export function convertNomination(gqlNomination: GqlNomination): NominationData {
  const n = R.pick(['id', 'slug', 'recordTime', 'tboLinkStatus', 'tboNumber'], gqlNomination)
  return {
    ...n,
    recordTime: parseISO(n.recordTime),
    owner: gqlNomination.owner ? gqlNomination.owner.username : null,
  }
}

export function convertNominationSummary(summary: LatestNominationVersionSummary): LatestNominationSummary {
  const { expectedDepartureTime, recordTime, owner } = summary
  const n = R.pick(['id', 'lane', 'origin', 'destination', 'towShape', 'tboLinkStatus', 'tboNumber'], summary)

  return {
    ...n,
    owner: owner ? owner.username : null,
    expectedDepartureTime: expectedDepartureTime ? parseISO(expectedDepartureTime) : null,
    recordTime: parseISO(recordTime),
  }
}

function convertTowConfiguration(towConfiguration: VersionedTowConfiguration): VersionedNominationTowConfiguration {
  return R.pick(
    [
      'boat',
      'goal',
      'hasTurnboat',
      'numberOfBarges',
      'numberOfEmptyBarges',
      'includeBargeIds',
      'excludeBargeIds',
      'numberOfStrings',
      'prioritizeHotBarges',
    ],
    towConfiguration
  )
}

function convertBargeFilters(bargeFilters: VersionedNominationFilters): VersionedNominationBargeFilers {
  const filters = R.pick(
    [
      'excludeBargeTypes',
      'excludeTboInfoBarges',
      'excludeTripStatuses',
      'excludeShuttleMoves',
      'lane',
      'maximumDraft',
      'expectedDepartureTime',
      'towOrigin',
      'towDestination',
      'includeTBOs',
    ],
    bargeFilters
  )
  return {
    ...filters,
    expectedDepartureTime: filters.expectedDepartureTime ? parseISO(filters.expectedDepartureTime) : null,
  }
}

export function convertNominationRequest(gqlTowConfiguration: GqlNominationRequest): VersionedNominationRequest {
  const { filters, configuration } = gqlTowConfiguration
  return {
    towConfiguration: convertTowConfiguration(configuration),
    bargeFilters: convertBargeFilters(filters),
  }
}
export type GqlTowBuildOrder = { latestInfo: string | null }

export function convertTowBuildOrder(tbo: GqlTowBuildOrder) {
  return R.pick(['latestInfo'], tbo)
}

export type GqlNominatedBarge = Omit<GqlFullNominatedBarge, 'towId' | 'isScheduledForPickup' | 'towBuildOrder'> & {
  towBuildOrder: { latestInfo: string | null } | null
}

export function convertNominatedBarge(
  gqlNominatedBarge: GqlNominatedBarge,
  maybeReview: NominationReview | null
): NominatedBargeWithReview {
  const bargeBase = R.pick(
    [
      'id',
      'name',
      'cargo',
      'hullType',
      'isPreselected',
      'isHot',
      'riskLevel',
      'pickupType',
      'tripStatus',
      'destination',
      'currentLocation',
      'loadStatus',
      'expectedLoadStatus',
      'pickupFacility',
      'dropOffFacility',
      'towBuildOrder',
      'type',
      'fleet',
      'distanceToPickup',
    ],
    gqlNominatedBarge
  )
  const review = maybeReview
    ? {
        receivedTBO: maybeReview.receivedTBO.find(_ => _.bargeId === gqlNominatedBarge.id)?.tbo,
        leftTheBargePool: maybeReview.leftTheBargePool.includes(gqlNominatedBarge.id),
        riskLevel: maybeReview.changedRiskLevel.find(_ => _.bargeId === gqlNominatedBarge.id)?.newRiskLevel,
      }
    : {
        receivedTBO: undefined,
        leftTheBargePool: false,
        riskLevel: undefined,
      }
  return {
    ...bargeBase,
    review,
    towBuildOrder: gqlNominatedBarge.towBuildOrder ? convertTowBuildOrder(gqlNominatedBarge.towBuildOrder) : null,
  }
}

export function convertTbnBarge(tbnBarge: GqlNominatedTbnBarge): TbnBarge {
  return R.pick(['pickupFacility', 'dropOffFacility', 'expectedLoadStatus'], tbnBarge)
}

export type GqlStopsWithMetrics = {
  dwellMinutes: number | null
  distanceToNextStop: number | null
  travelMinutesToNextStop: number | null
  stop: RiverLocationLite
}

export function convertStopsWithMetrics(gqlStop: GqlStopsWithMetrics, barges: NominatedBarge[]): StopsWithMetrics {
  const base = R.pick(['dwellMinutes', 'distanceToNextStop', 'travelMinutesToNextStop', 'stop'], gqlStop)
  const bargesToPickup = R.filter(barge => equals(gqlStop.stop, barge.pickupFacility), barges)
  const bargesToDrop = R.filter(barge => equals(gqlStop.stop, barge.dropOffFacility), barges)
  return {
    ...base,
    bargesToPickup,
    bargesToDrop,
  }
}

type GqlNominatedTow = NominatedTow

export function convertTow(gqlTow: GqlNominatedTow, review: NominationReview | null): Tow {
  const barges = R.map(barge => convertNominatedBarge(barge, review), gqlTow.barges)

  return {
    barges,
    tbnBarges: R.map(convertTbnBarge, gqlTow.tbnBarges),
    hasTurnboat: gqlTow.hasTurnboat,
    boat: R.pick(['boatId', 'name', 'abbreviation'], gqlTow.boat),
    stopsWithMetrics: R.map(s => convertStopsWithMetrics(s, barges), gqlTow.stopsWithMetrics),
    efficiencyMetric: gqlTow.efficiencyMetric,
  }
}

function convertOrderRevisionId(gqlOrderRevisionId: GqlOrderRevisionId): OrderRevisionId {
  return R.pick(['number', 'headerId', 'revisionNumber'], gqlOrderRevisionId)
}

export type GqlNominationVersion = Omit<
  GqlNominationVersionFull,
  'crossEfficiencyGoalScores' | 'nominationId' | 'tow' | 'nominationRequest' | 'owner'
> & {
  tow: GqlNominatedTow
  nominationRequest: GqlNominationRequest | null
  owner: { username: string } | null
}

function convertNativeNominationVersion(gqlNominationVersion: GqlNominationVersion): NativeNominationVersionData {
  return {
    id: gqlNominationVersion.id,
    slug: gqlNominationVersion.slug,
    recordTime: parseISO(gqlNominationVersion.recordTime),
    type: OverviewNominationVersionType.Native,
    nominationRequest: convertNominationRequest(gqlNominationVersion.nominationRequest!),
    tow: convertTow(gqlNominationVersion.tow, gqlNominationVersion.review),
    tboSubmissionStatus: gqlNominationVersion.tboSubmissionStatus ?? TboSubmissionStatusId.NotSubmitted,
    tboSubmissionMessage: gqlNominationVersion.tboSubmissionMessage,
    orderRevisionId: gqlNominationVersion.orderRevisionId
      ? convertOrderRevisionId(gqlNominationVersion.orderRevisionId)
      : null,
    owner: gqlNominationVersion.owner ? gqlNominationVersion.owner.username : null,
  }
}

function convertExternalNominationVersion(gqlNominationVersion: GqlNominationVersion): ExternalNominationVersionData {
  return {
    id: gqlNominationVersion.id,
    slug: gqlNominationVersion.slug,
    recordTime: parseISO(gqlNominationVersion.recordTime),
    type: OverviewNominationVersionType.External,
    nominationRequest: gqlNominationVersion.nominationRequest
      ? convertNominationRequest(gqlNominationVersion.nominationRequest)
      : null,
    tow: convertTow(gqlNominationVersion.tow, gqlNominationVersion.review),
    tboSubmissionStatus: TboSubmissionStatusId.Submitted,
    orderRevisionId: convertOrderRevisionId(gqlNominationVersion.orderRevisionId!),
    owner: gqlNominationVersion.owner ? gqlNominationVersion.owner.username : null,
  }
}

export function convertNominationVersion(gqlNominationVersion: GqlNominationVersion): NominationVersionData {
  return gqlNominationVersion.type === OverviewNominationVersionType.Native
    ? convertNativeNominationVersion(gqlNominationVersion)
    : convertExternalNominationVersion(gqlNominationVersion)
}
