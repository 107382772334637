import React from 'react'

import { Form, Formik } from 'formik'

import { DestinationSelectorCombobox } from '../../../components/Header/DestinationSelector/DestinationSelector'
import LaneSelector from '../../../components/Header/LaneSelector/LaneSelector'
import { OriginSelectorCombobox } from '../../../components/Header/OriginSelector/OriginSelector'
import { ErrorNotification } from '../../../ui/Form/ErrorNotifcation'

import FormOrSummary from './FormOrSummary'
import styles from './styles.module.scss'
import { LaneSelectionFormValues, LaneSelectionSummaryValues, type StageData } from './types'
import { laneSelectionInitialValues, laneSelectionValidationSchema } from './viewModel'

import type { HubLikeId, LaneId } from '../../../generated/graphql'

const LaneSelectionForm = ({
  data,
  onChange,
  initParameters,
}: {
  data: LaneSelectionFormValues
  onChange: (d: LaneSelectionFormValues) => void
  initParameters: any
}) => {
  const { lanes, origins, destinations } = initParameters

  return (
    <Formik
      initialValues={laneSelectionInitialValues}
      validationSchema={laneSelectionValidationSchema}
      onSubmit={() => {}}
      validateOnMount={false}
      validateOnBlur
      validateOnChange
    >
      {({ errors, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className={styles.formRow}>
            <label className={styles.label}>Lane</label>
            <div className={styles.inputContainer}>
              <LaneSelector
                lane={data.laneId}
                lanes={lanes}
                handleChange={item =>
                  setFieldValue('laneId', item as LaneId).then(() =>
                    onChange({ ...data, laneId: item as LaneId, origin: undefined, destination: undefined })
                  )
                }
              />
            </div>
            {errors?.laneId && <ErrorNotification message={errors.laneId} />}
          </div>

          <div className={styles.formRow}>
            <label className={styles.label}>Origin</label>
            <div className={styles.inputContainer}>
              <OriginSelectorCombobox
                origin={data.origin}
                origins={origins}
                handleChange={item =>
                  setFieldValue('origin', item as HubLikeId).then(() =>
                    onChange({ ...data, origin: item as HubLikeId, destination: undefined })
                  )
                }
              />
            </div>
            {errors?.origin && <ErrorNotification message={errors.origin} />}
          </div>

          <div className={styles.formRow}>
            <label className={styles.label}>Destination</label>
            <div className={styles.inputContainer}>
              <DestinationSelectorCombobox
                destination={data.destination}
                destinations={destinations}
                handleChange={item =>
                  setFieldValue('destination', item as HubLikeId).then(() =>
                    onChange({ ...data, destination: item as HubLikeId })
                  )
                }
              />
            </div>
            {errors?.destination && <ErrorNotification message={errors.destination} />}
          </div>
        </Form>
      )}
    </Formik>
  )
}

const LaneSelectionSummary = ({ description }: LaneSelectionSummaryValues) => {
  return <div>{description}</div>
}

const LaneSelectionBlock = ({
  isSelected,
  isValid,
  actions,
  form,
  summary,
  initParameters,
}: StageData<
  { data: LaneSelectionFormValues; onChange: (d: LaneSelectionFormValues) => void },
  LaneSelectionSummaryValues,
  any
>) => {
  const { data, onChange } = form

  return (
    <FormOrSummary
      title="Lane & Route"
      isSelected={isSelected}
      isValid={isValid}
      actions={actions}
      form={
        <LaneSelectionForm
          data={data}
          onChange={onChange}
          initParameters={initParameters}
        />
      }
      summary={<LaneSelectionSummary {...summary} />}
    />
  )
}

export default LaneSelectionBlock
