import { format } from 'date-fns'
import { utils, writeFileXLSX } from 'xlsx'

import { columns, columnKeys } from '../../../components/Table/BargesTable'
import { ELLIPSIS } from '../../../constants/constants'
import { BargeInPool } from '../../../generated/graphql'
import { browserInfo } from '../../../lib/browser-info'
import { toString } from '../../../utils/date'

import type { NominationData, NominationVersionData } from '../../../models/models'
import type { UserDetails } from '../../Account/AuthorizationContext'

export const collectVersionedFeedbackData = (
  userInfo: UserDetails,
  nomination: NominationData,
  versions: NominationVersionData[],
  bargePool: BargeInPool[],
  exportTime: Date
) => {
  const workbook = utils.book_new()
  const { email, cognitoUsername } = userInfo
  const info = browserInfo()

  const recordTimeString = toString(new Date(exportTime))

  const isPoolValid = (creationTime: Date, recordTime: Date): boolean => {
    const diffMinutes = (recordTime.getTime() - creationTime.getTime()) / (1000 * 60)
    return diffMinutes < 15
  }

  const feedbackSheetData = [
    ['User Info'],
    ['Email', email],
    ['Username', cognitoUsername],
    [],
    ['Browser Info'],
    ['Browser', info.browser],
    ['Browser Version', info.browserVersion],
    ['OS', info.os],
    ['Device', info.device],
    [],
    ['Nomination Info'],
    ['Nomination ID', nomination.id],
    ['TBO Link Status', nomination.tboLinkStatus],
    ['TBO Number', nomination.tboNumber ?? ELLIPSIS],
    [],
    ['Pool Export Time', recordTimeString],
    ['Nomination Version', 'Creation Time', 'Pool Validity'],
    ...versions.map(version => [
        version.id, 
        toString(version.recordTime),
        isPoolValid(version.recordTime,exportTime)
    ])
  ]

  const feedbackSheet = utils.aoa_to_sheet(feedbackSheetData)
  utils.book_append_sheet(workbook, feedbackSheet, 'Feedback')

  versions.forEach(version => {
    const versionData = [
      [`Version (${version.id})`],
      ['Type', version.type],
      ['Record Time', toString(version.recordTime)],
      ['Tow Boat', version.tow.boat?.name ?? ELLIPSIS],
      ['Total Barges', version.tow.barges.length],
      ['Turnboat', version.tow.hasTurnboat ? 'Yes' : 'No'],
      [],
      ['Barges'],
      ['ID', 'Name', 'Load Status'],
      ...version.tow.barges.map(barge => [barge.id, barge.name ?? ELLIPSIS, barge.loadStatus]),
      [],
      ['TBN Barges'],
      ['Pickup Facility', 'Dropoff Facility', 'Expected Load Status'],
      ...(version.tow.tbnBarges || []).map(tbn => [
        tbn.pickupFacility.name,
        tbn.dropOffFacility.name,
        tbn.expectedLoadStatus,
      ]),
    ]

    const versionSheet = utils.aoa_to_sheet(versionData)
    const creationDate = toString(version.recordTime || new Date())
      .replace(/[:\\/?*[\]]/g, '-')
      .trim()

    utils.book_append_sheet(workbook, versionSheet, `Version ${creationDate}`)
  })

  const bargePoolSheetData = [
    columnKeys.map(key => columns[key].label),
    ...bargePool.map(barge => columnKeys.map(key => columns[key].format(barge) ?? ELLIPSIS)),
  ]

  const bargePoolSheet = utils.aoa_to_sheet(bargePoolSheetData)
  utils.book_append_sheet(workbook, bargePoolSheet, 'Barge Pool')

  const exportDate = format(new Date(), 'yyyy-MM-dd')
  const exportFileName = `feedback-${nomination.id}-${exportDate}.xlsx`
  writeFileXLSX(workbook, exportFileName)
}
